import React from "react"
import classNames from "classnames"
import { SectionSplitProps } from "utils/SectionProps"
import SectionHeader from "components/layout/partials/SectionHeader"
import Image from "components/elements/Image"


import wgen_image from "assets/images/hair_1.png"
import blender_live_share_image from "assets/images/blender_live_share.png"
import shading_image from "assets/images/shading.png"
import usd_editing_image from "assets/images/usd_editing.png"
import scenelib_image from "assets/images/light_2.png"


import LayoutDefault from "layouts/LayoutDefault"
import ModalVideo from "components/elements/ModalVideo"
import SolutionFeaturesTiles from "components/layout/en/SolutionFeaturesTiles"
import Cta from "components/layout/en/Cta"
import ButtonGroup from "components/elements/ButtonGroup"
import RequestAccessButton from "components/layout/RequestAccessButton"

const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

const Index = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  )

  const sectionHeader = {
    title: [
      <>
       Wizart <span className="text-color-primary">DCC</span> Platform <br/>
       for <span className="text-color-primary">Blender</span> Pipeline
      </>
    ],
    paragraph:
      <> <span className="text-color-primary"> missing piece</span> for your heavy blender workflows </>
  }

  return (
    <LayoutDefault {...props}>
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content"
              style={{
                paddingBottom: 32,
              }}
            />
            <div className="center-content">
              <ButtonGroup>
                <RequestAccessButton name={"Request Access"} />
              </ButtonGroup>
            </div>
            <div className={splitClasses}>
            <SolutionFeaturesTiles/>
            
            <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">USD editing</h3>
                  <p className="m-0">
                    Wizart DCC supports industry standard manipulators that authors <b>USDXform</b> attributes. Supported features like various snapping modes and edit pivot.
                    You can group, duplicate and rename prims.
                    We draw visual locators for <b>USDLux</b> and <b>UsdCamera</b> prim types, that you could be often missing in <b>usdview</b>.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="-UeXXgjN7yY"
                    width={528}
                    height={396}
                    thumbnailSrc = {usd_editing_image}
                  />
                </div>
              </div>

            <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    In development
                  </div>
                  <h3 className="mt-0 mb-12">USD Live Share</h3>
                  <p className="m-0">
                    Using the <b>Live Share</b> function, we can use rich <b>Blender</b> modeling and sculpting toolset to send live edits between <b>Blender</b> and <b>Wizart DCC</b> processes.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="q2tp1D4R7zY"
                    width={528}
                    height={396}
                    thumbnailSrc = {blender_live_share_image}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Hair System
                  </div>
                  <h3 className="mt-0 mb-12">WGEN</h3>
                  <p className="m-0">
                    Wgen is node-based hair system with support of industry standard{" "}
                    <a href="https://github.com/AcademySoftwareFoundation/OpenShadingLanguage">
                      OSL
                    </a>{" "}. OSL networks allow you procedurally change curves shape or change hair color using textures.
                    Using included toolset for sculpting the hair, an artist can create any haircut for your character.                    
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="k6FaTvMmPYM"
                    width={528}
                    height={396}
                    thumbnailSrc = {wgen_image}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    In development
                  </div>
                  <h3 className="mt-0 mb-12">Shading</h3>
                  <p className="m-0">
                    Node Editor allow you to modify any USDShade network for any target renderer in market, including <a href="https://github.com/tangent-opensource/hdBlackbird">HdCycles</a>.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="qDqClwhsQU4"
                    width={528}
                    height={396}
                    thumbnailSrc={shading_image}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    In development
                  </div>
                  <h3 className="mt-0 mb-12">Lighting</h3>
                  <p className="m-0">
                  Scenelib is a powerful node-based system for procedural scene description right-before render.
                  Lighting artists can visually create a sequence or
                  shot lighting using the SceneLib node graph. The resulting scenegraph data could be
                  rendered using <b>Blender Cycles</b>.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="jzzCV-r927U"
                    width={528}
                    height={396}
                    thumbnailSrc={scenelib_image}
                  />
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>
      <Cta split topDivider />
    </LayoutDefault>
  )
}

Index.propTypes = propTypes
Index.defaultProps = defaultProps

export default Index